import { useTranslation } from 'next-i18next';

import Template from 'templates/Default';

import { useRouterMiddleware } from 'hooks/useRouterMiddleware';

import routes from 'config/routes';

import Button from 'components/atom/Form/Button';
import Logo from 'components/atom/Logo';

import { Container } from './styles';

const Page404: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useRouterMiddleware();

  return (
    <Template>
      <Container>
        <div>
          <Logo />

          <h4>{t('page404:sub-title')}</h4>
          <p>{t('page404:description')}</p>

          <Button
            theme="blue"
            text={t('page404:back-to-site')}
            onClick={() => navigateTo(routes.dashboard)}
          />
        </div>
      </Container>
    </Template>
  );
};

export default Page404;
