import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';

import Template from 'templates/Default';

import Page404 from 'pages/Page404';

import i18nFiles from 'config/i18n-files';
import seo from 'config/seo';

const pageLang = 'page404';

const MyPage404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title={`${seo().title} - ${t(`${pageLang}:title`)}`} />

      <Template>
        <Page404 />
      </Template>
    </>
  );
};

export default MyPage404;

export const getStaticProps: GetStaticProps = async (context) => ({
  props: {
    ...(await serverSideTranslations(context.locale || 'ptBR', i18nFiles)),
  },
});
